import * as yup from 'yup';
import { initialProviderFilter } from 'src/store/providerFilter/types';
import { selectProviderFilter } from 'src/store/providerFilter/selector';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

export const providerFilterSchema = yup.object().shape({
  availableForMatch: yup.boolean(),
  contractType: yup.array().of(yup.string()),
  email: yup.string(),
  expertise: yup.array().of(yup.string()),
  firstname: yup.string(),
  id: yup.string(),
  lastname: yup.string(),
  methodology: yup.array().of(yup.string()),
  phone: yup.string(),
  pricingGroupAlias: yup.array().of(yup.string()),
  userState: yup.array().of(yup.string()),
  userType: yup.array().of(yup.string())
});

export const useFilterForm = () => {
  const storeProviderFilter = useTypedSelector(selectProviderFilter);
  const formMethods = useForm({
    defaultValues: initialProviderFilter,
    mode: 'all',
    resolver: yupResolver(providerFilterSchema)
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(storeProviderFilter);
  }, [reset, storeProviderFilter]);

  return formMethods;
};
