export interface ProviderCompensationFilter {
  providerCompensationId?: string | null;
  providerId?: string | null;
  reconciliationId?: string | null;
}

export const initialProviderCompensationFilter: ProviderCompensationFilter = {
  providerCompensationId: null,
  providerId: null,
  reconciliationId: null
};

export interface ProviderCompensationFilterState {
  providerCompensationFilter: ProviderCompensationFilter;
}

export const initialProviderCompensationFilterState: ProviderCompensationFilterState = {
  providerCompensationFilter: initialProviderCompensationFilter
};
