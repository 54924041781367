import { InvoicingStage } from 'src/views/invoice/model/invoiceListItem';

export interface InvoiceFilter {
  id: string;
  invoicingStage: InvoicingStage;
  receiverUserId: string;
  reconciliationId: string;
}

export const initialInvoiceFilter: InvoiceFilter = {
  id: '',
  invoicingStage: null,
  receiverUserId: '',
  reconciliationId: ''
};

export interface InvoiceFilterState {
  invoiceFilter: InvoiceFilter;
}

export const initialInvoiceFilterState: InvoiceFilterState = {
  invoiceFilter: initialInvoiceFilter
};
