import * as yup from 'yup';
import { selectMeetingFilter } from 'src/store/meetingFilter/selector';
import { sponsorshipTypes } from 'src/views/client/list/types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

export const meetingFilterSchema = yup.object().shape({
  clientId: yup.string(),
  hasProviderFeedback: yup.boolean(),
  id: yup.string(),
  meetingHeld: yup.boolean(),
  meetingType: yup.array().of(yup.string()),
  providerEarnType: yup.array().of(yup.string()),
  providerEarned: yup.boolean(),
  providerId: yup.string(),
  sponsorshipType: yup
    .string()
    .nullable()
    .oneOf([...sponsorshipTypes, '']),
  windowEnd: yup.string(),
  windowStart: yup.string()
});

export const useFilterForm = () => {
  const storeFilter = useTypedSelector(selectMeetingFilter);
  const formMethods = useForm({
    defaultValues: storeFilter,
    mode: 'all',
    resolver: yupResolver(meetingFilterSchema)
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(storeFilter);
  }, [reset, storeFilter]);

  return formMethods;
};
