import { AuthContext } from './AuthContext';
import { LoginReducerTypes, loginReducer, loginReducerInitialState } from './reducer';
import { TwoFactorSetupType, UserAuthTokens } from 'src/auth/types';
import { USER_AUTH_TOKENS_KEY, isTokenExpired, setSession } from './utils';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import LocalStorageUtil from 'src/utils/localStorage';
import axios, { endpoints } from 'src/utils/axios';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(loginReducer, loginReducerInitialState);

  const clearSession = async () => {
    await setSession(null);
    dispatch({
      payload: {
        user: null
      },
      type: LoginReducerTypes.INITIAL
    });
  };

  const updateSession = async (userAuthTokens: UserAuthTokens) => {
    await setSession(userAuthTokens);
    dispatch({
      payload: {
        user: {
          accessToken: userAuthTokens.token,
          roles: userAuthTokens.roles,
          userId: userAuthTokens.userId
        }
      },
      type: LoginReducerTypes.INITIAL
    });
  };

  const initialize = useCallback(async () => {
    try {
      const userAuthTokens = LocalStorageUtil.getItem<UserAuthTokens>(USER_AUTH_TOKENS_KEY);

      if (!userAuthTokens) {
        clearSession();
        return;
      }

      const jwtExpired = isTokenExpired(userAuthTokens.tokenExpire);

      if (!jwtExpired) {
        updateSession(userAuthTokens);
        return;
      }

      const refreshExpired = isTokenExpired(userAuthTokens.refreshTokenExpire);

      if (refreshExpired) {
        clearSession();
        return;
      }

      try {
        const response = await axios.post<UserAuthTokens>(endpoints.auth.refreshToken, {
          token: userAuthTokens.refreshToken
        });

        const newTokens = response.data;

        updateSession(newTokens);
      } catch (refreshError) {
        // eslint-disable-next-line no-console
        console.error(refreshError);
        clearSession();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      clearSession();
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGOUT
  const logout = useCallback(async () => {
    await setSession(null);
    dispatch({
      type: LoginReducerTypes.LOGOUT
    });
  }, []);

  const userCredentialsSubmitted = useCallback(
    async (twoFactorSetup: TwoFactorSetupType, email: string) => {
      dispatch({
        payload: {
          twoFactorSetup,
          user: {
            email
          }
        },
        type: LoginReducerTypes.USER_CREDENTIALS_SUCCESS
      });
    },
    []
  );

  const twoFactorCodeSubmitted = useCallback(
    async (userAuthTokens: UserAuthTokens) => {
      dispatch({
        payload: {
          user: {
            ...state.user,
            accessToken: userAuthTokens.token,
            roles: userAuthTokens.roles,
            userId: userAuthTokens.userId
          }
        },
        type: LoginReducerTypes.TWO_FACTOR_AUTH_SUCCESS
      });

      await setSession(userAuthTokens);
    },
    [state.user]
  );

  const isUserInRole = useCallback(
    (roles: string[]) => {
      if (roles == null || roles.length === 0) return true;
      if (!state.user?.roles) {
        return false;
      }
      return roles.some((role) => state.user.roles.includes(role));
    },
    [state.user]
  );
  const checkAuthenticated = state.user?.accessToken ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      authenticated: status === 'authenticated',
      isUserInRole,
      loading: status === 'loading',
      logout,
      step: state.step,
      twoFactorCodeSubmitted,
      twoFactorSetup: state.twoFactorSetup,
      user: state.user,
      userCredentialsSubmitted
    }),
    [
      isUserInRole,
      logout,
      state.step,
      state.twoFactorSetup,
      state.user,
      status,
      twoFactorCodeSubmitted,
      userCredentialsSubmitted
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
