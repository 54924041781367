export interface SponsorshipFilter {
  activationCode: string;
  birthday: string;
  createdAt: string;
  effectiveFromEndDate: string;
  effectiveFromStartDate: string;
  firstname: string;
  lastname: string;
  maxUsageCounter: number;
  minUsageCounter: number;
  planAlias: string;
  showOnlyEmptyActivationCode: boolean;
  sponsorId: string;
}

export const initialSponsorshipFilter: SponsorshipFilter = {
  activationCode: '',
  birthday: '',
  createdAt: '',
  effectiveFromEndDate: '',
  effectiveFromStartDate: '',
  firstname: '',
  lastname: '',
  maxUsageCounter: null,
  minUsageCounter: null,
  planAlias: '',
  showOnlyEmptyActivationCode: null,
  sponsorId: null
};

export interface SponsorshipFilterState {
  sponsorshipFilter: SponsorshipFilter;
}

export const initialSponsorshipFilterState: SponsorshipFilterState = {
  sponsorshipFilter: initialSponsorshipFilter
};
