export interface ProviderFilter {
  availableForMatch: boolean;
  contractType: string[];
  email: string;
  expertise: string[];
  firstname: string;
  id: string;
  lastname: string;
  methodology: string[];
  phone: string;
  pricingGroupAlias?: string[];
  userState: string[];
  userType: string[];
}

export const initialProviderFilter: ProviderFilter = {
  availableForMatch: false,
  contractType: [],
  email: '',
  expertise: [],
  firstname: '',
  id: '',
  lastname: '',
  methodology: [],
  phone: '',
  pricingGroupAlias: [],
  userState: [],
  userType: []
};

export interface ProviderFilterState {
  providerFilter: ProviderFilter;
}

export const initialProviderFilterState: ProviderFilterState = {
  providerFilter: initialProviderFilter
};
