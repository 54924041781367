import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ReconciliationFilter {
  billingType?: string | null;
  endDate?: Date | null;
  reconciliationStatus?: string | null;
  startDate?: Date | null;
}

export interface ReconciliationFilterState {
  reconciliationFilter: ReconciliationFilter;
}

export const initialReconciliationFilterV2: ReconciliationFilter = {
  billingType: null,
  endDate: null,
  reconciliationStatus: null,
  startDate: null
};

export const initialReconciliationFilterStateV2: ReconciliationFilterState = {
  reconciliationFilter: initialReconciliationFilterV2
};

const reconciliationFilterSlice = createSlice({
  initialState: initialReconciliationFilterStateV2,
  name: 'reconciliationFilter',
  reducers: {
    clearFilter: (draft) => {
      draft.reconciliationFilter = initialReconciliationFilterV2;
    },
    removeBillingType: (draft) => {
      draft.reconciliationFilter.billingType = initialReconciliationFilterV2.billingType;
    },
    removeEndDate: (draft) => {
      draft.reconciliationFilter.endDate = initialReconciliationFilterV2.endDate;
    },
    removeReconciliationStatus: (draft) => {
      draft.reconciliationFilter.reconciliationStatus =
        initialReconciliationFilterV2.reconciliationStatus;
    },
    removeStartDate: (draft) => {
      draft.reconciliationFilter.startDate = initialReconciliationFilterV2.startDate;
    },
    updateFilter: (draft, action: PayloadAction<ReconciliationFilter>) => {
      draft.reconciliationFilter = { ...initialReconciliationFilterV2, ...action.payload };
    }
  }
});

export default reconciliationFilterSlice.reducer;
export const {
  clearFilter,
  removeBillingType,
  removeEndDate,
  removeReconciliationStatus,
  removeStartDate,
  updateFilter
} = reconciliationFilterSlice.actions;
