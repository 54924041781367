import * as yup from 'yup';
import { initialUserPlanPrototypeFilter } from 'src/store/userPlanPrototypeFilter/types';
import { selectUserPlanPrototypeFilter } from 'src/store/userPlanPrototypeFilter/selector';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

export const userPlanPrototypeFilterSchema = yup.object().shape({
  alias: yup
    .string()
    .nullable()
    .test('is-valid-length', 'Alias must be at least 3 characters', (value) => {
      if (value === null || value === '') return true;
      return value.length >= 3;
    }),
  verticalPlanPrototypeAlias: yup.string().nullable()
});

export const useFilterForm = () => {
  const storeFilter = useTypedSelector(selectUserPlanPrototypeFilter);
  const formMethods = useForm({
    defaultValues: initialUserPlanPrototypeFilter,
    mode: 'all',
    resolver: yupResolver(userPlanPrototypeFilterSchema)
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(storeFilter);
  }, [reset, storeFilter]);

  return formMethods;
};
