import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  UserPlanPrototypeFilter,
  initialUserPlanPrototypeFilter,
  initialUserPlanPrototypeFilterState
} from './types';

const sponsorshipFilterSlice = createSlice({
  initialState: initialUserPlanPrototypeFilterState,
  name: 'userPlanPrototypeFilter',
  reducers: {
    clearFilter: (draft) => {
      draft.userPlanPrototypeFilter = initialUserPlanPrototypeFilter;
    },
    removeFilterItem: (draft, action: PayloadAction<string>) => {
      const key = action.payload as keyof UserPlanPrototypeFilter;

      draft.userPlanPrototypeFilter[key] = initialUserPlanPrototypeFilter[key] as any;
    },
    updateFilter: (draft, action: PayloadAction<UserPlanPrototypeFilter>) => {
      draft.userPlanPrototypeFilter = { ...initialUserPlanPrototypeFilter, ...action.payload };
    }
  }
});

export default sponsorshipFilterSlice.reducer;
export const { clearFilter, removeFilterItem, updateFilter } = sponsorshipFilterSlice.actions;
