export interface UserPlanPrototypeFilter {
  alias: string;
  verticalPlanPrototypeAlias: string;
}

export const initialUserPlanPrototypeFilter: UserPlanPrototypeFilter = {
  alias: '',
  verticalPlanPrototypeAlias: ''
};

export interface UserPlanPrototypeFilterState {
  userPlanPrototypeFilter: UserPlanPrototypeFilter;
}

export const initialUserPlanPrototypeFilterState: UserPlanPrototypeFilterState = {
  userPlanPrototypeFilter: initialUserPlanPrototypeFilter
};
