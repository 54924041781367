import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ProviderCompensationFilter,
  initialProviderCompensationFilter,
  initialProviderCompensationFilterState
} from './types';

const providerCompensationFilterSlice = createSlice({
  initialState: initialProviderCompensationFilterState,
  name: 'providerCompensationFilter',
  reducers: {
    clearFilter: (draft) => {
      draft.providerCompensationFilter = initialProviderCompensationFilter;
    },
    removeProviderCompensationId: (draft) => {
      draft.providerCompensationFilter.providerCompensationId =
        initialProviderCompensationFilter.providerCompensationId;
    },
    removeProviderId: (draft) => {
      draft.providerCompensationFilter.providerId = initialProviderCompensationFilter.providerId;
    },
    removeReconciliationId: (draft) => {
      draft.providerCompensationFilter.reconciliationId =
        initialProviderCompensationFilter.reconciliationId;
    },
    updateFilter: (draft, action: PayloadAction<ProviderCompensationFilter>) => {
      draft.providerCompensationFilter = {
        ...initialProviderCompensationFilter,
        ...action.payload
      };
    }
  }
});

export default providerCompensationFilterSlice.reducer;
export const {
  clearFilter,
  removeProviderCompensationId,
  removeProviderId,
  removeReconciliationId,
  updateFilter
} = providerCompensationFilterSlice.actions;
