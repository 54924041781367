import { UserAuthTokens, UserRoleType } from '../types';
import { isAfter } from 'date-fns';
import LocalStorageUtil from 'src/utils/localStorage';
import axios, { axiosInsightsInstance } from 'src/utils/axios';

export const USER_AUTH_TOKENS_KEY = 'userTokens';

export const isTokenExpired = (expiryDate: string) => {
  const currentDate = new Date();
  const parsedExpiryDate = new Date(expiryDate);

  return isAfter(currentDate, parsedExpiryDate);
};

export const setSession = async (userTokens: UserAuthTokens | null) => {
  if (userTokens) {
    LocalStorageUtil.setItem(USER_AUTH_TOKENS_KEY, userTokens);

    axios.defaults.headers.common.Authorization = `Bearer ${userTokens.token}`;
    axiosInsightsInstance.defaults.headers.common.Authorization = `Bearer ${userTokens.token}`;
  } else {
    LocalStorageUtil.removeItem(USER_AUTH_TOKENS_KEY);

    delete axios.defaults.headers.common.Authorization;
    delete axiosInsightsInstance.defaults.headers.common.Authorization;
  }
};

export const userRoles: Record<UserRoleType, string[]> = {
  eventManager: ['EventManager', 'Admin'],
  notificationSender: ['NotificationSender', 'Admin'],
  reconciliationManager: ['ReconciliationManager', 'Admin'],
  sponsorManager: ['SponsorManager', 'Admin']
};
