import * as yup from 'yup';
import { initialSponsorshipFilter } from 'src/store/sponsorshipFilter/types';
import { selectSponsorshipFilter } from 'src/store/sponsorshipFilter/selector';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

export const sponsorshipFilterSchema = yup.object().shape({
  activationCode: yup.string().nullable(),
  birthday: yup.string().nullable(),
  createdAt: yup.string().nullable(),
  effectiveFromEndDate: yup.string().nullable(),
  effectiveFromStartDate: yup.string().nullable(),
  firstname: yup.string().nullable(),
  lastname: yup.string().nullable(),
  maxUsageCounter: yup.number().nullable(),
  minUsageCounter: yup.number().nullable(),
  planAlias: yup.string().nullable(),
  sponsorId: yup.string().nullable()
});

export const useFilterForm = () => {
  const storeSponsorshipFilter = useTypedSelector(selectSponsorshipFilter);
  const formMethods = useForm({
    defaultValues: initialSponsorshipFilter,
    mode: 'all',
    resolver: yupResolver(sponsorshipFilterSchema)
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(storeSponsorshipFilter);
  }, [reset, storeSponsorshipFilter]);

  return formMethods;
};
