import { ActionTaken, IssueType } from 'src/store/messaging/types';
import { GridPaginationModel } from '@mui/x-data-grid';
import { USER_AUTH_TOKENS_KEY, isTokenExpired, setSession } from 'src/auth/context/utils';
import { UserAuthTokens } from 'src/auth/types';
import LocalStorageUtil from './localStorage';
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';

interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
  retry?: boolean;
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL
});

export const axiosInsightsInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_INSIGHTS_API_BASE_URL
});

let isRefreshing = false;
let failedQueue: Array<(token?: string) => void> = [];

const processQueue = (error: AxiosError | null = null, token: string | null = null) => {
  failedQueue.forEach((callback) => {
    if (error) {
      callback(undefined);
    } else {
      callback(token);
    }
  });
  failedQueue = [];
};

const addToFailedQueue = (originalRequest: any) =>
  new Promise((resolve, reject) => {
    failedQueue.push((token?: string) => {
      // If token is defined, resend the request with the new token

      if (token) {
        // eslint-disable-next-line no-param-reassign
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(axiosInstance(originalRequest));
      } else {
        // If token is undefined, which we couldn't refresh token, reject the promise
        reject(new Error('Token refresh failed'));
      }
    });
  });

const refreshAuthToken = async (): Promise<string | null> => {
  const userAuthTokens = LocalStorageUtil.getItem<UserAuthTokens>(USER_AUTH_TOKENS_KEY);

  if (userAuthTokens && !isTokenExpired(userAuthTokens.refreshTokenExpire)) {
    try {
      const response = await axiosInstance.post(endpoints.auth.refreshToken, {
        token: userAuthTokens.refreshToken,
        userId: userAuthTokens.userId
      });

      const newTokens = response.data;
      await setSession(newTokens);

      return newTokens.token;
    } catch (err) {
      console.error('Token refresh failed:', err);
      LocalStorageUtil.removeItem(USER_AUTH_TOKENS_KEY);
      return null;
    }
  } else {
    LocalStorageUtil.removeItem(USER_AUTH_TOKENS_KEY);
    return null;
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as ExtendedAxiosRequestConfig;

    if (error.response?.status === 401 && !originalRequest?.retry) {
      // If we are refreshing the token, we should add the request to the failed queue
      if (isRefreshing) {
        return addToFailedQueue(originalRequest);
      }

      originalRequest.retry = true;
      isRefreshing = true;

      const newToken = await refreshAuthToken();
      isRefreshing = false;

      if (newToken) {
        // If token has been refreshed, we should retry the original request with the new token and process the queue
        processQueue(null, newToken);
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      }

      // If token refresh failed, we should process the queue with error
      processQueue(error, null);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

export const endpoints = {
  auth: {
    login: '/kerberos/auth/login',
    logout: '/kerberos/auth/logout',
    refreshToken: '/kerberos/auth/refresh-token',
    submit2FA: '/kerberos/auth/submit2facode'
  },
  checkout: {
    refundCheckout: (checkoutId: string) => `/kerberos/checkout/${checkoutId}/refund`
  },
  clients: {
    assignProvider: '/kerberos/user/ReassignProfessional',
    dismissProvider: '/kerberos/user/DismissProfessional',
    getClient: (id: string) => `/kerberos/user/${id}`,
    getClients: '/kerberos/user',
    getInsightsProfile: (id: string) => `/profile/${id}`,
    giveMeetingRight: '/kerberos/GiveMeetingRight',
    sendNotification: '/kerberos/Notification/Send',
    takeMeetingRight: '/kerberos/TakeMeetingRight',
    updateInsightsProfile: `/profile`
  },
  events: {
    activateEvent: (id: string) => `/kerberos/event/${id}/activate`,
    createEvent: '/kerberos/event',
    deleteEvent: (id: string) => `/kerberos/event/${id}`,
    deleteEventNotification: (eventId: string, notificationId: string) =>
      `/kerberos/event/${eventId}/notification/${notificationId}`,
    deleteEventStage: (eventId: string, stageId: string) =>
      `/kerberos/event/${eventId}/stage/${stageId}`,

    endEvent: (id: string) => `/kerberos/event/${id}/end`,
    getEvent: (id: string) => `/Kerberos/Event/${id}`,
    getEventParticipants: (id: string) => `/kerberos/event/${id}/participants`,
    getEvents: '/kerberos/event/events',
    updateEvent: (id: string) => `/kerberos/event/${id}`,
    updateEventNotification: (id: string) => `/kerberos/event/${id}/Notification`,
    updateEventStage: (id: string) => `/kerberos/event/${id}/stage`
  },
  health: '/healthCheck/ping',
  issues: {
    assingIssue: (issueId: string, assigneeEmail: string) =>
      `/KerberosIssue/AssignIssue?issueId=${issueId}&assigneeEmail=${assigneeEmail}`,
    closeIssue: (
      issueId: string,
      issueType: IssueType,
      explanation: string,
      actionTaken: ActionTaken
    ) =>
      `/KerberosIssue/CloseIssue?issueId=${issueId}&type=${issueType}&explanation=${explanation}&actionTaken=${actionTaken}`,
    createIssue: (userId: string) => `/KerberosIssue/CreateIssueForChatSession?userId=${userId}`,
    disownIssue: (issueId: string) => `/KerberosIssue/DisownIssue?issueId=${issueId}`,
    filterIssues: `/KerberosIssue/FilterUserIssueGroups`,
    getMessage: (messageId: string) => `/KerberosIssue/GetSupportMessage?messageId=${messageId}`,
    getMessages: (sessionId: string, limit: Number) =>
      `/KerberosIssue/SupportMessages?sessionId=${sessionId}&limit=${limit}`,
    getUserIssueGroup: (sessionId: string) =>
      `/KerberosIssue/SupportSessions?sessionId=${sessionId}`,
    getUserIssueGroupById: (sessionId: string) =>
      `/KerberosIssue/UserIssueGroups?sessionId=${sessionId}`,
    getUserIssueGroups: '/KerberosIssue/UserIssueGroups',
    mergeIssue: (masterIssueId: string, issueToMerge: string) =>
      `/KerberosIssue/MergeIssues?masterIssueId=${masterIssueId}&issueToMerge=${issueToMerge}`,
    sendSupportMessage: (sessionId: string) =>
      `/KerberosIssue/SendSupportMessage?sessionId=${sessionId}`,
    tendToIssue: (issueId: string) => `/KerberosIssue/TendToIssue?issueId=${issueId}`
  },
  meetingPackageOptions: {
    createMeetingPackageOption: '/kerberos/meetingPackageOption/meetingPackageOption',
    deleteMeetingPackageOption: (id: string) =>
      `/kerberos/meetingPackageOption/meetingPackageOption/${id}`,
    getMeetingPackageOption: (id: string) =>
      `/kerberos/meetingPackageOption/meetingPackageOption/${id}`,
    getMeetingPackageOptions: '/kerberos/meetingPackageOption/meetingPackageOptions',
    updateMeetingPackageOption: (id: string) =>
      `/kerberos/meetingPackageOption/meetingPackageOption/${id}`
  },
  meetings: {
    cancelMeeting: 'kerberos/meeting',
    getMeeting: (id: string) => `/kerberos/meeting/${id}`,
    getMeetings: 'kerberos/meeting',
    updateProviderEarned: '/kerberos/meeting/UpdateProviderEarned'
  },
  messagingHub: '/messaginghub',
  pricingGroups: {
    createPricingGroup: '/kerberos/pricingGroup',
    getPricingGroups: '/kerberos/pricingGroup',
    updatePricingGroup: '/kerberos/pricingGroup/update'
  },
  providerCompensation: {
    getProviderCompensation: (id: string) => `/kerberos/ProviderCompensation/Detail/${id}`,
    listProviderCompensations: `/kerberos/ProviderCompensation/List`
  },
  providers: {
    activateProvider: '/kerberos/provider/activate',
    completeOnboarding: '/kerberos/provider/completeOnboarding',
    getProvider: (id: string) => `/kerberos/provider/${id}`,
    getProviderClients: (id: string) => `/kerberos/provider/${id}/clients`,
    getProviders: '/kerberos/provider',
    offboardProvider: '/kerberos/provider/deactivate',
    startOnboarding: '/kerberos/provider/startOnboarding',
    updateAutoMessaging: '/kerberos/provider/updateAutoMessaging',
    updateClientScheduling: '/kerberos/provider/updateclientscheduling',
    updateContactInfo: '/kerberos/provider/contactInfo',
    updatePhysicalSessionPreference: '/kerberos/Professionals/PhysicalSessionsPreference',
    updateReconciliationInfo: '/kerberos/provider/reconciliationInfo'
  },
  reconciliation: {
    approveReconciliation: `/kerberos/reconciliation/Approve`,
    cancelReconciliation: `/kerberos/reconciliation/Cancel`,
    clearProviderCompensationInvoices: `/kerberos/reconciliation/ClearProviderCompensationInvoices`,
    createReconciliation: `/kerberos/reconciliation`,
    deleteReconciliation: (id: string) => `/kerberos/sponsor/${id}`,
    getInvoice: (id: string) => `/kerberos/reconciliation/Invoice/${id}`,
    getReconciliation: (id: string) => `/kerberos/reconciliation/Detail/${id}`,
    getReconciliationSpreadsheets: `/kerberos/reconciliation/Spreadsheets`,
    listInvoices: `/kerberos/reconciliation/Invoice/List`,
    listReconciliations: `/kerberos/reconciliation/List`,
    meetingsWithNoFeedback: `/kerberos/reconciliation/MeetingsWithNoFeedback`,
    processInvoicesForReconciliation: `/kerberos/reconciliation/ProcessInvoicesForReconciliation`,
    recalculateReconciliation: `/kerberos/reconciliation/Recalculate`,
    sendClinicHeadEmails: `/kerberos/reconciliation/SendClinicHeadEmails`,
    sendInvoiceEmails: `/kerberos/reconciliation/SendInvoiceEmails`,
    sendInvoicingInformationEmails: `/kerberos/reconciliation/SendInvoicingInformationEmails`,
    sendProviderEmails: `/kerberos/reconciliation/SendProviderEmails`
  },
  sponsors: {
    addSponsor: `/kerberos/sponsor`,
    deleteSponsor: (id: string) => `/kerberos/sponsor/${id}`,
    editSponsor: (id: string) => `/kerberos/sponsor/${id}`,
    getSponsor: (id: string) => `/kerberos/sponsor/${id}`,
    getSponsors: '/kerberos/sponsor'
  },
  sponsorships: {
    bulkCreateWithCode: '/kerberos/sponsorship/bulk/code',
    createSponsorship: '/kerberos/sponsorship',
    getSponsorshipById: (id: string) => `/kerberos/sponsorship/${id}`,
    getSponsorshipFileLogList: (sponsorId: string) =>
      `/kerberos/sponsorship/sponsorshipFileLogs/${sponsorId}`,
    getSponsorships: '/kerberos/sponsorship',
    updateSponsorship: (id: string) => `/kerberos/sponsorship/${id}`,
    uploadSponsorshipFile: '/Kerberos/Sponsorship/bulk/file'
  },
  userGroups: {
    addUsersToUserGroup: '/kerberos/usergroup/addUsersToGroup',
    createUserGroup: '/kerberos/usergroups',
    getUserGroupAliases: '/kerberos/usergroupAliases',
    getUserGroupById: (id: string) => `/kerberos/usergroups/${id}`,
    getUserGroups: '/kerberos/usergroups',
    removeUserFromUserGroup: '/kerberos/usergroup/removeUserFromGroup'
  },
  userPlanPrototypes: {
    createUserPlanPrototype: '/kerberos/planprototype/UserPlanPrototype',
    getUserPlanPrototypeById: (id: string) => `/Kerberos/PlanPrototype/userPlanPrototype/${id}`,
    getUserPlanPrototypes: '/kerberos/planprototype/UserPlanPrototype',
    updateUserPlanPrototype: '/kerberos/planprototype/UserPlanPrototype'
  },
  verticalPlanPrototype: {
    createVerticalPlanPrototype: '/kerberos/planprototype/VerticalPlanPrototype',
    getVerticalPlanPrototype: (id: string) => `/kerberos/planprototype/VerticalPlanPrototype/${id}`,
    getVerticalPlanPrototypes: '/kerberos/planprototype/VerticalPlanPrototype',
    updateVerticalPlanPrototype: `/kerberos/planprototype/VerticalPlanPrototype/`
  }
};

export function createQueryString(
  params: Record<string, any>,
  paginationModel: GridPaginationModel = null,
  includeFalses: boolean = false
): string {
  const { page, pageSize } = paginationModel ?? {};

  const paginationParams = {
    page: page + 1,
    pageSize
  };

  const allParams = { ...paginationParams, ...params };

  const queryString = Object.entries(allParams)
    .flatMap(([key, value]) => {
      if (value === null || value === undefined) {
        return null;
      }

      if (Array.isArray(value)) {
        return value.map((val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
      }

      if (typeof value === 'boolean') {
        return includeFalses || value
          ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          : '';
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .filter(Boolean)
    .join('&');

  return queryString ? `?${queryString}` : '';
}
