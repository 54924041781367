import { UserType } from 'src/common/models';

export type InvoiceListItem = {
  createdAt?: Date;
  edocumentCreatedAt?: Date;
  edocumentFilledAt?: Date;
  emailSent: boolean;
  id?: string;
  invoicingStage?: InvoicingStage;
  issueDate?: Date;
  lastEmailSentAt?: Date;
  lastUpdateAt: Date;
  receiverUserId: string;
  receiverUserType: UserType;
  reconciliationId?: string;
  status: number;
  totalAmount: number;
  vendorInvoiceCreatedAt?: Date;
};

export enum InvoicingStage {
  Completed = 'Completed',
  EdocumentFailed = 'EdocumentFailed',
  EdocumentSubmitted = 'EdocumentSubmitted',
  EdocumentSuccessful = 'EdocumentSuccessful',
  NotStarted = 'NotStarted',
  VendorInvoiceCreated = 'VendorInvoiceCreated',
  WaitingEinvoiceLegalization = 'WaitingEinvoiceLegalization'
}
