import { MeetingType } from 'src/views/meeting/list/types';
import { SponsorshipType } from 'src/views/client/list/types';

export interface MeetingFilter {
  clientId: string;
  hasProviderFeedback: boolean;
  id: string;
  meetingHeld: boolean;
  meetingType: MeetingType[] | [];
  providerEarnType: string[];
  providerEarned: boolean;
  providerId: string;
  sponsorshipType: SponsorshipType;
  windowEnd: string;
  windowStart: string;
}

export const initialMeetingFilter: MeetingFilter = {
  clientId: '',
  hasProviderFeedback: false,
  id: '',
  meetingHeld: false,
  meetingType: [],
  providerEarnType: [],
  providerEarned: false,
  providerId: '',
  sponsorshipType: '',
  windowEnd: '',
  windowStart: ''
};

export interface MeetingFilterState {
  meetingFilter: MeetingFilter;
}

export const initialMeetingFilterState: MeetingFilterState = {
  meetingFilter: initialMeetingFilter
};
