import * as yup from 'yup';
import { InvoicingStage } from 'src/views/invoice/model/invoiceListItem';
import { selectInvoiceFilter } from 'src/store/invoiceFilter/selector';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from 'src/hooks';
import { yupResolver } from '@hookform/resolvers/yup';

export const invoiceFilterSchema = yup.object().shape({
  id: yup.string(),
  invoicingStage: yup.string().nullable().oneOf(Object.values(InvoicingStage)),
  receiverUserId: yup.string(),
  reconciliationId: yup.string()
});

export const useFilterForm = () => {
  const storeFilter = useTypedSelector(selectInvoiceFilter);
  const formMethods = useForm({
    defaultValues: storeFilter,
    mode: 'all',
    resolver: yupResolver(invoiceFilterSchema)
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(storeFilter);
  }, [reset, storeFilter]);

  return formMethods;
};
