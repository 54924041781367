import { InvoiceFilter, initialInvoiceFilter, initialInvoiceFilterState } from './types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const invoiceFilterSlice = createSlice({
  initialState: initialInvoiceFilterState,
  name: 'invoiceFilter',
  reducers: {
    removeId: (draft) => {
      draft.invoiceFilter.id = initialInvoiceFilter.id;
    },
    removeInvoicingStage: (draft) => {
      draft.invoiceFilter.invoicingStage = initialInvoiceFilter.invoicingStage;
    },
    removeReceiverUserId: (draft) => {
      draft.invoiceFilter.receiverUserId = initialInvoiceFilter.receiverUserId;
    },
    removeReconciliationId: (draft) => {
      draft.invoiceFilter.reconciliationId = initialInvoiceFilter.reconciliationId;
    },
    updateFilter: (draft, action: PayloadAction<InvoiceFilter>) => {
      draft.invoiceFilter = { ...initialInvoiceFilter, ...action.payload };
    }
  }
});

export default invoiceFilterSlice.reducer;
export const {
  removeId,
  removeInvoicingStage,
  removeReceiverUserId,
  removeReconciliationId,
  updateFilter
} = invoiceFilterSlice.actions;
