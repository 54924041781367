export type FirstLevelRoute =
  | 'client'
  | 'dashboard'
  | 'event'
  | 'login'
  | 'meetings'
  | 'messages'
  | 'plan'
  | 'provider'
  | 'reconciliation'
  | 'sponsor'
  | 'sponsorship';

type FirstLevelRouteWithPrefix = `/${FirstLevelRoute}`;

export const firstLevelRoutes: Record<FirstLevelRoute, FirstLevelRouteWithPrefix> = {
  client: '/client',
  dashboard: '/dashboard',
  event: '/event',
  login: '/login',
  meetings: '/meetings',
  messages: '/messages',
  plan: '/plan',
  provider: '/provider',
  reconciliation: '/reconciliation',
  sponsor: '/sponsor',
  sponsorship: '/sponsorship'
};

// ----------------------------------------------------------------------

export const paths = {
  client: {
    detail: `${firstLevelRoutes.client}/:id`,
    list: `${firstLevelRoutes.client}/list`,
    root: `${firstLevelRoutes.client}`
  },
  dashboard: {
    root: firstLevelRoutes.dashboard
  },
  events: {
    create: `${firstLevelRoutes.event}/create`,
    detail: `${firstLevelRoutes.event}/:id`,
    list: `${firstLevelRoutes.event}/list`,
    root: firstLevelRoutes.event
  },
  login: firstLevelRoutes.login,
  meetings: {
    detail: `${firstLevelRoutes.meetings}/:id`,
    list: `${firstLevelRoutes.meetings}/list`,
    root: firstLevelRoutes.meetings
  },
  messages: {
    detail: `${firstLevelRoutes.messages}/:id`,
    list: `${firstLevelRoutes.messages}/list`,
    root: firstLevelRoutes.messages
  },
  page404: '/404',
  plans: {
    meetingPackageOptions: {
      create: `${firstLevelRoutes.plan}/meeting-package-options/create`,
      detail: `${firstLevelRoutes.plan}/meeting-package-options/:id`,
      list: `${firstLevelRoutes.plan}/meeting-package-options/list`,
      root: `${firstLevelRoutes.plan}/meeting-package-options`
    },
    root: firstLevelRoutes.plan,
    userPlanPrototypes: {
      create: `${firstLevelRoutes.plan}/user-plan-prototypes/create`,
      detail: `${firstLevelRoutes.plan}/user-plan-prototypes/:id`,
      list: `${firstLevelRoutes.plan}/user-plan-prototypes/list`,
      root: `${firstLevelRoutes.plan}/user-plan-prototypes`
    },
    verticalPlanPrototypes: {
      create: `${firstLevelRoutes.plan}/vertical-plan-prototypes/create`,
      detail: `${firstLevelRoutes.plan}/vertical-plan-prototypes/:id`,
      list: `${firstLevelRoutes.plan}/vertical-plan-prototypes/list`,
      root: `${firstLevelRoutes.plan}/vertical-plan-prototypes`
    }
  },
  provider: {
    detail: `${firstLevelRoutes.provider}/:id`,
    list: `${firstLevelRoutes.provider}/list`,
    pricingGroups: `${firstLevelRoutes.provider}/pricing-groups`,
    professionalGroups: {
      detail: `${firstLevelRoutes.provider}/professional-groups/:id`,
      list: `${firstLevelRoutes.provider}/professional-groups/list`,
      root: `${firstLevelRoutes.provider}/professional-groups`
    },
    root: firstLevelRoutes.provider
  },
  reconciliations: {
    detail: `${firstLevelRoutes.reconciliation}/:id`,
    invoices: {
      detail: `${firstLevelRoutes.reconciliation}/invoices/:id`,
      list: `${firstLevelRoutes.reconciliation}/invoices/list`,
      root: `${firstLevelRoutes.reconciliation}/invoices`
    },
    list: `${firstLevelRoutes.reconciliation}/list`,
    providerCompensations: {
      detail: `${firstLevelRoutes.reconciliation}/provider-compensations/:id`,
      list: `${firstLevelRoutes.reconciliation}/provider-compensations/list`,
      root: `${firstLevelRoutes.reconciliation}/provider-compensations`
    },

    root: firstLevelRoutes.reconciliation
  },
  sponsors: {
    detail: `${firstLevelRoutes.sponsor}/:id`,
    list: `${firstLevelRoutes.sponsor}/list`,
    root: firstLevelRoutes.sponsor
  },
  sponsorships: {
    create: `${firstLevelRoutes.sponsorship}/create`,
    detail: `${firstLevelRoutes.sponsorship}/:id`,
    list: `${firstLevelRoutes.sponsorship}/list`,
    root: firstLevelRoutes.sponsorship
  },
  temp: '/temp'
};
