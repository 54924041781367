import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  SponsorshipFilter,
  initialSponsorshipFilter,
  initialSponsorshipFilterState
} from './types';

const sponsorshipFilterSlice = createSlice({
  initialState: initialSponsorshipFilterState,
  name: 'sponsorshipFilter',
  reducers: {
    clearFilter: (draft) => {
      draft.sponsorshipFilter = initialSponsorshipFilter;
    },
    removeFilterItem: (draft, action: PayloadAction<string>) => {
      const key = action.payload as keyof SponsorshipFilter;

      if (key === 'showOnlyEmptyActivationCode') {
        draft.sponsorshipFilter.showOnlyEmptyActivationCode =
          initialSponsorshipFilter.showOnlyEmptyActivationCode;
      } else if (key === 'minUsageCounter') {
        draft.sponsorshipFilter.minUsageCounter = initialSponsorshipFilter.minUsageCounter;
      } else if (key === 'maxUsageCounter') {
        draft.sponsorshipFilter.maxUsageCounter = initialSponsorshipFilter.maxUsageCounter;
      } else {
        draft.sponsorshipFilter[key] = initialSponsorshipFilter[key] as any;
      }
    },
    updateFilter: (draft, action: PayloadAction<SponsorshipFilter>) => {
      draft.sponsorshipFilter = { ...initialSponsorshipFilter, ...action.payload };
    }
  }
});

export default sponsorshipFilterSlice.reducer;
export const { clearFilter, removeFilterItem, updateFilter } = sponsorshipFilterSlice.actions;
